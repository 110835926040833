import { render, staticRenderFns } from "./a-question-header.vue?vue&type=template&id=3fcd241c&scoped=true&lang=pug&"
import script from "./a-question-header.vue?vue&type=script&lang=ts&"
export * from "./a-question-header.vue?vue&type=script&lang=ts&"
import style0 from "./a-question-header.vue?vue&type=style&index=0&id=3fcd241c&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fcd241c",
  null
  
)

export default component.exports