














export default {
	props: {
		question: { required: true },
		count: { required: false },
		totalCount: { required: false }
	},

	computed: {
		answerStatusClasses: function() {
			if (this.question.type == 'field') {
				return {
					'w-color-primary': this.question.answers[0].body,
					'w-color-yellow': !this.question.answers[0].body
				}
			}

			return {
				'w-color-green': this.question.answers?.length && this.question.is_correct,
				'w-color-wrong-red': this.question.answers?.length && !this.question.is_correct,
				'w-color-primary': !this.question.answers?.length
			}
		},

		answerStatusText: function() {
			if (this.question.type == 'field') {
				return `${this.question.answers[0].body ? '' : 'ne'}vyplnené`
			}

			return !this.question.answers?.length ? 'nevyplnené' : `${this.question.is_correct ? '' : 'ne'}správna odpoveď`
		}
	}
}
